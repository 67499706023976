import React, { useState } from 'react';
import { Input, Avatar , Table, Col, Row} from 'antd';
import './App.css';
import data from "./new_data.json";
import { head } from "lodash";

const { Search } = Input;

function App() {
    const [ filteredData, setFilteredData ] = useState(data);

    const filterData = (e) => {
        const searchInput = e.target.value;
        const remove_after = searchInput.indexOf('.');
        const searchTerm =  remove_after !== -1 ? searchInput.substring(0, remove_after).toLowerCase() : searchInput.toLowerCase();

        setFilteredData(data.map(alcohol => {
                return {
                    "category" : alcohol.category,
                    "data": alcohol.data.filter(item => {
                        return item.distillery.toLowerCase().includes(searchTerm) || item.code.includes(searchTerm) || item.region.toLowerCase().includes(searchTerm) || (item.note && item.note.toLowerCase().includes(searchTerm))
                    })
                }
            })
        );
    }

    const urlifyString = distillery => {
        return `https://www.smws.com/${distillery.replace(/ *\([^)]*\) */g, "").replace(/\s+/g, '-').toLowerCase()}`;
    };

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: code => (
                <Avatar style={{backgroundColor: '#342210'}}>{code}</Avatar>
            ),
            width: 55,
        },
        {
            title: 'Distillery',
            dataIndex: 'distillery',
            key: 'distillery',
            render: distillery => <a target="_blank" rel="noopener noreferrer" href={urlifyString(distillery)}>{distillery}</a>,
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: "region"
        },

        {
            title: 'Notes',
            dataIndex: 'note',
            key: "note"
        },
    ];

    return (
        <div className="App">
            <Row gutter={16}>
                <Col>
                    <div className="title">
                        <img src="./logo.png" style={{maxWidth: '7rem' }} alt="The Whisky Explorer Logo" />
                        <h1>SMWS Code Lookup</h1>
                    </div>
                    <div className="dataContainer">
                        <Search
                            size="large"
                            placeholder="Enter distillery code, name or region."
                            onChange={e => filterData(e)}
                            style={{
                                marginBottom: 20
                            }}
                            allowClear
                        />
                        <Row gutter={16}>
                            {data.map(alcohol =>
                                <Col xl={12} key={alcohol.category}>
                                    <h2>{alcohol.category}</h2>
                                    <Table
                                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                                        size={"small"}
                                        dataSource={head(filteredData.filter(item => item.category === alcohol.category)).data}
                                        pagination={false}
                                        scroll={{ y: 400 ,x: 400}}
                                        columns={columns}
                                        style={{
                                            marginBottom: 20
                                        }}
                                        rowKey="code"
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                    <div className="footer"><a href="https://www.thewhiskyexplorer.co.uk">© The Whisky Explorer {new Date().getFullYear()}</a></div>
                </Col>
            </Row>
        </div>
    )
}

export default App;
